import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ShoppingCartOutlined } from '@mui/icons-material';
import CommonMask from '@/helpers/masks';
import { CartType } from '@/models/cartModel';
import { ListProductsResponse } from '@/models/listProducts.response';
import { useCart } from '@/contexts/delivery/cart';
import ModalInfo from '@/components/ModalInfo';

type FooterType = {
	totValue: number;
	productQtd: number;
	setProductQtd: React.Dispatch<React.SetStateAction<number>>;
	onDismiss(): void;
	setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
	unityValue: number;
	integrationCode: string;
	idUnity: number;
	fieldObs: string;
	variableName: string;
	options: CartType['items'][0]['options'];
	product: ListProductsResponse['conteudo'][0] | null;
	maxQtd: number;
};

export default function FooterComp({ totValue, productQtd, setProductQtd, integrationCode, onDismiss, setOpenCart, idUnity, unityValue, options, variableName, product, fieldObs, maxQtd }: FooterType) {
	const { Add, cart } = useCart();
	const [isDisabled, setIsDisabled] = useState(false);
	const [requiredOptions, setRequiredOptions] = useState<any>([]);
	const [showSnack, setShowSnack] = useState(false);
	const [snackMsg, setSnackMsg] = useState('');

	function handleModal(state: boolean) {
		setShowSnack(state);
	}

	useEffect(() => {
		const newRequiredArr: any = [];
		product?.opcionais.forEach(optional => {
			if (optional.selecao_obg) {
				newRequiredArr.push({
					id: optional.id,
					min: optional.qtde_min_selecao,
				});
				setIsDisabled(true);
			}
		});

		setRequiredOptions(newRequiredArr);
	}, []);

	useEffect(() => {
		handleModal(false);

		if (options) {
			let anyRequired = false;
			requiredOptions.forEach((required: any) => {
				let qtd = 0;
				options.forEach(option => {
					if (option.catId === required.id) {
						qtd = qtd + option.qtd;
					}
				});

				if (qtd >= required.min) {
					if (anyRequired === false) {
						setIsDisabled(false);
					}
				} else {
					anyRequired = true;
					setIsDisabled(true);
				}
			});
		}
	}, [options]);

	function handleCart() {
		if (isDisabled) {
			setSnackMsg('Você precisa selecionar todos os opcionais obrigatórios!');
			setShowSnack(true);
		} else {
			handleModal(false);
			if (product) {
				const newCart: CartType['items'][0] = {
					id: product.id,
					img: product.imagens[0].imagem,
					unityValue,
					idUnity,
					qtd: productQtd,
					title: product.nome,
					options,
					variable: variableName,
					observation: fieldObs,
					maxQtd: maxQtd,
					produtos_relacionados: product.produtos_relacionados,
					qtde_add: product.unidade_medida[0].qtde_add,
					qtde_minima: product.unidade_medida[0].qtde_minima,
					integrationCode: integrationCode,
				};
				Add(newCart);
				onDismiss();
				setOpenCart(true);
			}
		}
	}

	return (
		<footer className="footer">
			<div className="footerBtn">
				<Button variant="text" className="footerQtdBtn" onClick={() => (productQtd > product?.unidade_medida[0].qtde_minima! ? setProductQtd(productQtd - product?.unidade_medida[0]?.qtde_add!) : null)}>
					<RemoveIcon />
				</Button>
				<span className="footerQtdTxt">{Number.isInteger(productQtd) ? productQtd.toFixed(0) : productQtd.toFixed(2)}</span>
				<Button
					variant="text"
					className="footerQtdBtn"
					onClick={() => {
						if (product && (productQtd < maxQtd || maxQtd === 0)) {
							setProductQtd(productQtd + product?.unidade_medida[0]?.qtde_add!);
						}
					}}>
					<AddIcon />
				</Button>
			</div>
			<Button variant="contained" className="footerBtn flex-1" onClick={handleCart}>
				<ShoppingCartOutlined />
				<span className="footerValue">R$ {CommonMask.currency(totValue.toFixed(2).toString())}</span>
			</Button>
			<ModalInfo open={showSnack} handler={handleModal} message={snackMsg} />
		</footer>
	);
}
